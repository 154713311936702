import IntervalTree from '@flatten-js/interval-tree';
import {
    CellClassParams,
    CellFocusedEvent,
    CellPosition,
    ColDef,
    ColSpanParams,
    Column,
    IRowNode,
    GetContextMenuItemsParams,
    GetRowIdParams,
    GridApi,
    ProcessCellForExportParams,
    ProcessDataFromClipboardParams,
    RowSpanParams,
    ValueGetterParams,
    ValueSetterParams,
    TextMatcherParams,
    ToolPanelClassParams,
} from 'ag-grid-community';
import classNames from 'classnames';
import { parse } from 'date-fns';
import { cloneDeep, isEmpty, isEqual, isObject } from 'lodash-es';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    ACTION_COLUMN,
    ANALYTE_LIST_ID,
    ANALYTICAL_METHODS_LIST_ID,
    COLOUR,
    COORDINATES_ID,
    emptySampleTypeID,
    ERROR_DIRECTION,
    INFO_COLUMN,
    OUT_OF_ORDER,
    PADDED_ROW_SPAN,
    PARENT_ROW_SPAN,
    PARENT_SAMPLE,
    PARENT_SKIP_LITHOLOGY_ROW,
    RANKED_RESULTS,
    ROW_DRAG,
    ROW_NUMBER,
    ROW_SPAN,
    SAMPLE_CONTROL_TYPE_COL_ID,
    SAMPLE_CONTROL_TYPE_ID,
    SAMPLE_DECOMPOSITION_LIST_ID,
    SAMPLE_INFO_COLUMNS,
    SAMPLE_NUMBER_COL_ID,
    SAMPLE_RESULTS,
    SAMPLE_STATUS,
    SAMPLE_TYPE_COL_ID,
    SAMPLE_TYPE_STATE,
    STATUS_TYPES,
    TEXT_FIELD_MAX_LENGTH,
    TIMEOUT,
    UNIT_LIST_ID,
} from 'state-domains/constants';
import {
    CellData,
    createListRow,
    DrillHoleReferencesColumnType,
    DrillHoleReferencesIntervalType,
    RowReference,
    SampleResultValues,
} from 'state-domains/domain/drillhole';
import { isValidNumber } from 'state-domains/domain/drillhole/validations';
import { List, ListInfo, Row, TableView } from 'state-domains/domain/subscription';
import { convertToSnake } from 'state-domains/utils';
import { SectionType } from 'src/components/PropertySection';
import { generateObjectId } from 'src/utilities/formatters';
import { PASTE_OPTIONS } from 'src/utilities/types';

import { CandidateEntries } from '../DrillholeTableLanding/DrillHoleTableProperties/DrillholeTableProperties.types';

import {
    AlertModalOptions,
    OverwriteType,
    TableViewDrillHoleIds,
    TableViewState,
} from './DataGrid.types';

export const specialFields = ['from', 'to', 'length', 'depth'];
const commonGroup = 'common';

export const getRowId = (params: GetRowIdParams) => params.data.id;

export const isFillDownEnabledOnTable = (tableViewState: TableViewState) => {
    const {
        isSample,
        readOnly,
        tableView = '',
        linkedToLithology,
        isSingleRowTable,
    } = tableViewState;
    return !(
        isSample ||
        readOnly ||
        linkedToLithology ||
        tableView.includes(COORDINATES_ID) ||
        isSingleRowTable
    );
};

export const filterColumns = (columns: any[], customFilterFunction: Function = () => true) => {
    const filterFunc = (y: ColDef) => {
        if ([...specialFields, SAMPLE_TYPE_STATE, SAMPLE_STATUS].includes(y.colId ?? ''))
            return true;

        let result: boolean = y.cellEditorParams.visibleColumn;

        if (result) {
            result = customFilterFunction(y);
        }

        return result;
    };

    if (columns.length > 0 && columns[0].groupId) {
        return columns.map((x) =>
            x.groupId !== commonGroup && x.groupId !== SAMPLE_INFO_COLUMNS
                ? {
                      ...x,
                      children: x.children.filter((y: ColDef) => filterFunc(y)),
                  }
                : x,
        );
    }

    return columns.filter((x) => filterFunc(x));
};

export const attachFilters = (columns: any[], filterCollection: any) => {
    if (columns.length > 0 && columns[0].groupId) {
        return columns.map((x) =>
            x.groupId === commonGroup
                ? {
                      ...x,
                      children: x.children.map((y: ColDef) =>
                          y.colId !== 'length'
                              ? { ...y, filterParams: { filterOptions: filterCollection } }
                              : y,
                      ),
                  }
                : x,
        );
    }

    return columns.map((x: ColDef) =>
        specialFields.includes(x.colId ?? '') && x.colId !== 'length'
            ? { ...x, filterParams: { filterOptions: filterCollection } }
            : x,
    );
};

export const getColumnSpanning = (params: ColSpanParams) =>
    params.data[PADDED_ROW_SPAN]?.value ? params.colDef.cellEditorParams.columnCount : 1;
export const getRowSpanning = (params: RowSpanParams) => {
    const paddedRowSpan = !params.data[PARENT_SKIP_LITHOLOGY_ROW]?.value
        ? (params.data[PADDED_ROW_SPAN]?.value ?? 1)
        : 1;

    return params.colDef.cellEditorParams.linkedToLitho
        ? (params.data[ROW_SPAN]?.value ?? 1)
        : paddedRowSpan;
};

export const getGroupIdForColumn = (colId: string, columns: any[]) => {
    for (const columnGroup of columns) {
        if ('groupId' in columnGroup) {
            for (const column of columnGroup.children) {
                if (colId === column.colId) {
                    return columnGroup.groupId;
                }
            }
        } else {
            return null;
        }
    }
    return null;
};

export const focusOnCellInGrid = (
    gridApi: GridApi | null | undefined,
    rowIndex: number,
    col: string,
) => {
    gridApi?.clearFocusedCell();
    gridApi?.clearCellSelection();
    gridApi?.ensureIndexVisible(rowIndex);
    gridApi?.setFocusedCell(rowIndex, col);
};

export const focusOnCellInNextRow = (
    rowNode: IRowNode | undefined,
    col: string,
    gridApi: GridApi | undefined | null,
) => {
    if (rowNode?.rowIndex !== null && rowNode?.rowIndex !== undefined) {
        let nextRow = gridApi?.getDisplayedRowAtIndex(rowNode.rowIndex + 1);
        gridApi?.clearFocusedCell();
        gridApi?.clearCellSelection();
        if (nextRow) {
            let column = col;
            let rowIndex = nextRow.rowIndex;
            while (nextRow?.data?.[PADDED_ROW_SPAN]?.value) {
                // Find a real row. In case we don't have a real row go to the from column
                if (nextRow?.rowIndex !== null) {
                    nextRow = gridApi?.getDisplayedRowAtIndex(nextRow.rowIndex + 1);
                    if (nextRow && !nextRow.data?.[PADDED_ROW_SPAN]?.value) {
                        rowIndex = nextRow?.rowIndex;
                        column = col;
                    } else {
                        column = 'from';
                    }
                } else {
                    nextRow = undefined;
                }
            }
            setTimeout(() => {
                gridApi?.setFocusedCell(rowIndex ?? 0, column);
            });
        } else {
            setTimeout(() => {
                gridApi?.setFocusedCell(rowNode.rowIndex ?? 0, col);
            });
        }
    }
};

export const isReadOnlyCell = (params: any) => {
    if (!params?.colDef?.type || params.colDef?.cellRendererParams?.configGrid) {
        return false;
    }
    const isLinkToLitho =
        params.colDef.cellEditorParams?.linkedToLitho ||
        (params.data?.id?.includes('-') && !params.data.lastRow);

    if (params.colDef.cellEditorParams?.readOnly) {
        // Whole grid is read only
        return true;
    }
    // Check if the cell is editable
    let editable;
    if (typeof params.colDef.editable === 'function') {
        editable = params.colDef.editable(params);
    } else {
        editable = params.colDef.editable;
    }

    if (
        params.colDef.type[0] === DrillHoleReferencesColumnType.CHECKBOX ||
        params.colDef.type[0] === DrillHoleReferencesColumnType.FILE ||
        (params.colDef.type[0] === DrillHoleReferencesColumnType.IMAGO &&
            !isEmpty(params.data[params.colDef.colId]?.value)) ||
        isLinkToLitho ||
        params.colDef.colId === SAMPLE_TYPE_COL_ID ||
        params.colDef.type[0] === PARENT_SAMPLE
    ) {
        // Checkbox or Sample type is always enabled.
        editable = true;
    }

    // Cell is not editable including checkbox, if it's associated to other column
    editable =
        editable && (!params.colDef.cellEditorParams.isAssociatedToOtherColumn || isLinkToLitho);
    return (
        !editable &&
        params.colDef.type[0] !== DrillHoleReferencesColumnType.ASSOCIATED_COLUMN &&
        !params.colDef?.cellRendererParams?.isForList
    );
};

export const sideBar = (hideFilter = false) => ({
    toolPanels: [
        {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
            },
        },
        ...(hideFilter
            ? []
            : [
                  {
                      id: 'filters',
                      labelDefault: 'Filters',
                      labelKey: 'filters',
                      iconKey: 'filter',
                      toolPanel: 'agFiltersToolPanel',
                      toolPanelParams: {
                          suppressExpandAll: false,
                          suppressFilterSearch: false,
                      },
                  },
              ]),
    ],
});

export const defaultColDef = (classes: any = null): ColDef => ({
    enableCellChangeFlash: true,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    lockPinned: false,
    resizable: true,
    filter: true,

    valueGetter: (params: ValueGetterParams) => {
        const key = String(params.colDef.colId);
        const cell: CellData = params.data[key];
        if (!cell) return null;

        if (params.colDef.colId === SAMPLE_TYPE_COL_ID) {
            // If sample type is empty we return an empty sample type id. This helps with
            // the comparator to not highlight the cells if sample type is empty
            return {
                ...cell.value,
                ...(!cell.value && { id: emptySampleTypeID }),
                childSamples: params.data.childSamples,
            };
        }
        if (params.colDef.type?.[0] === DrillHoleReferencesColumnType.PRIMARY_LIST_COLUMN) {
            return {
                text: cell.value,
                colour: params.data[COLOUR].value,
            };
        }

        return cell.value;
    },
    valueSetter: (params: ValueSetterParams) => {
        // Value on the grid will be set upon successful return from the api except checkbox
        if (
            Array.isArray(params.colDef.type) &&
            (params.colDef.type[0] === DrillHoleReferencesColumnType.CHECKBOX ||
                params.colDef.type[0] === DrillHoleReferencesColumnType.IN_USE)
        ) {
            const key = String(params.colDef.colId);
            const cell = params.data[key] || new CellData();

            params.data[key] = { ...cell, value: params.newValue };
            return true;
        }
        return false;
    },
    filterValueGetter: (params: ValueGetterParams) => {
        const colId = params.colDef?.colId ?? '';
        const cell = params.data[colId];
        if (!cell) return null;

        let retval;

        const type = params.colDef.type ? params.colDef.type[0] : '';
        const collection = [];
        const value = params.data[colId].value;
        switch (type) {
            case DrillHoleReferencesColumnType.NUMERIC:
            case INFO_COLUMN:
                retval =
                    type === DrillHoleReferencesColumnType.NUMERIC
                        ? {
                              value: cell.value,
                              depth: { errors: params.node?.data.depth?.errors ?? [] },
                              from: { errors: params.node?.data.from?.errors ?? [] },
                              to: { errors: params.node?.data.to?.errors ?? [] },
                              error: !!cell?.errors?.length,
                          }
                        : cell.value;
                break;
            case DrillHoleReferencesColumnType.LIST:
            case DrillHoleReferencesColumnType.ASSOCIATED_COLUMN:
                collection.push(params.data[colId]?.value?.primary ?? '');
                if (cell?.errors?.length) collection.push('(Errors)');
                retval = collection;
                break;
            case DrillHoleReferencesColumnType.DATE:
                retval = { value: undefined, error: !!cell?.errors?.length };
                if (cell.value?.date) {
                    const dateString = new Date(cell.value.date).toISOString().split('T')[0];
                    const date = parse(dateString, 'yyyy-MM-dd', new Date());
                    retval = { ...retval, value: date };
                }
                break;
            case DrillHoleReferencesColumnType.PRIMARY_LIST_COLUMN:
            case DrillHoleReferencesColumnType.TEXT:
            case DrillHoleReferencesColumnType.TEXTMULTI:
                retval = JSON.stringify({
                    value: params.data[colId].value,
                    error: !!params.data[colId].errors?.length,
                });
                break;
            case SAMPLE_INFO_COLUMNS:
                retval =
                    colId === SAMPLE_STATUS
                        ? (STATUS_TYPES[value]?.in18nId?.defaultMessage ?? '(Blanks)')
                        : (value ?? '(Blanks)');
                break;
            case SAMPLE_RESULTS:
            case RANKED_RESULTS:
                retval = params.data[colId].value.value.value;
                break;
            case DrillHoleReferencesColumnType.IN_USE:
            case DrillHoleReferencesColumnType.CHECKBOX:
                retval = params.data[colId].value ? 'TRUE' : 'FALSE';
                break;
            default:
                retval = params.getValue(colId);
        }

        return retval;
    },
    toolPanelClass: (params: ToolPanelClassParams) => {
        const checkboxCondition = !!params.colDef.tooltipComponentParams?.hideColumnSelect;
        return classes
            ? classNames({
                  [classes.colummnToolPanelCheckbox]: checkboxCondition,
                  [classes.columnDragHandleRemove]:
                      (params.colDef as ColDef).lockPinned ||
                      !!(params.colDef as ColDef).lockPosition ||
                      (params.colDef as ColDef).suppressMovable,
              })
            : '';
    },
    cellClassRules: {
        errorCell: (params: CellClassParams) => {
            if (params.node.id?.includes('-')) return false;

            const data = params.colDef.colId ? params.data[params.colDef.colId] : null;
            return data ? data.errors.length > 0 : false;
        },
        disabledCell: (params: CellClassParams) => {
            const type = params.colDef.type?.[0] ?? '';
            if (params?.colDef?.cellRendererParams?.alwaysReadOnly) return true;
            if (
                [
                    ROW_DRAG,
                    INFO_COLUMN,
                    SAMPLE_RESULTS,
                    RANKED_RESULTS,
                    SAMPLE_INFO_COLUMNS,
                    ACTION_COLUMN,
                ].includes(type)
            )
                return !!params.colDef?.cellEditorParams?.readOnly;

            return params.colDef?.cellEditorParams?.readOnly
                ? params.colDef?.cellEditorParams?.readOnly
                : isReadOnlyCell(params);
        },
        limitedPadding: (params: CellClassParams) => {
            const colType = params.colDef.type?.[0];
            return (
                colType === DrillHoleReferencesColumnType.LIST ||
                colType === DrillHoleReferencesColumnType.ASSOCIATED_COLUMN
            );
        },
        cellStatusPadding: (params: CellClassParams) => {
            const colType = params.colDef.type?.[0];
            return colType === SAMPLE_INFO_COLUMNS || colType === ROW_DRAG;
        },
        cellSpan: (params: CellClassParams) =>
            params.colDef.cellEditorParams?.linkedToLitho
                ? params.data[ROW_SPAN]?.value > 1
                : params.data[PADDED_ROW_SPAN]?.value > 1,
        paddedSpanColour: (params: CellClassParams) =>
            !params.colDef.cellEditorParams?.linkedToLitho &&
            params.data[PADDED_ROW_SPAN]?.value > 1,
        toggleSpan: (params: CellClassParams) =>
            (!params.data[PARENT_SKIP_LITHOLOGY_ROW]?.value &&
                params.data[PADDED_ROW_SPAN]?.value > 1 &&
                !params.colDef.cellEditorParams?.linkedToLitho) ||
            (params.data[ROW_SPAN]?.value > 1 && params.colDef.cellEditorParams?.linkedToLitho),
    },
});

export const findAllSampleChildren = (gridApi: GridApi, row: any, samples: any): any => {
    samples = { ...samples, [row.id]: row[SAMPLE_NUMBER_COL_ID].value };

    if (row.childSamples) {
        Object.keys(row.childSamples).forEach((x) => {
            const newRow = gridApi.getRowNode(x)?.data;

            if (newRow) {
                samples = findAllSampleChildren(gridApi, newRow, samples);
            }
        });
    }

    return samples;
};

export const createTableCandidateEntries = (
    event: CellFocusedEvent,
    selectedNode: IRowNode | undefined,
) => {
    const candidateEntries: CandidateEntries[] = [];
    const rankedColumn = event?.column as Column;

    (rankedColumn?.getColDef()?.cellEditorParams?.candidateColumns ?? []).forEach((x: string) => {
        const column = event.api?.getColumn(x)?.getColDef() ?? {};
        const columnData = selectedNode?.data[column?.colId ?? '']?.value?.value;

        if (!isEmpty(column)) {
            candidateEntries.push({
                key: `${column?.colId ?? ''}`,
                column: column.headerName ?? '',
                value: String(columnData?.value ?? ''),
                status: columnData?.status ?? '',
            });
        }
    });

    return candidateEntries;
};

export const createSampleResultTableProperties = (
    event: CellFocusedEvent,
    selectedNode: IRowNode | undefined,
    tableView: TableView,
    isRanked: boolean,
) => {
    const sampleResultsProperties = {
        value: 'Value',
        originalValue: 'Original Value',
        symbolAction: 'Symbol Action',
        symbolReason: 'Symbol Reason',
        status: 'Status',
        originalStatus: 'Original Status',
        crmCode: 'CRM Code',
        crmType: 'CRM Type',
        crmCertifiedValue: 'CRM Certified Value',
        crm_1sd: 'CRM 1 Standard Deviation',
        crmMinValue: 'CRM Min Value',
        crmMaxValue: 'CRM Max Value',
        validationAction: 'Validation Action',
        validationReason: 'Validation Reason',
    };

    const column = event?.column as Column;
    const colId = column?.getColId() ?? '';
    const sampleResultValues = (selectedNode?.data[colId]?.value?.value ??
        {}) as SampleResultValues;

    const values: SectionType[] = [
        {
            key: 'sampleNumber',
            label: 'Sample Number',
            value: selectedNode?.data[SAMPLE_NUMBER_COL_ID]?.value ?? '',
        },
        {
            key: 'sampleType',
            label: 'Sample Type',
            value: selectedNode?.data[SAMPLE_TYPE_COL_ID]?.value?.primary ?? '',
        },
        ...(selectedNode?.data[SAMPLE_TYPE_COL_ID]?.value?.id !== SAMPLE_CONTROL_TYPE_ID &&
        tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL
            ? [
                  {
                      key: 'interval',
                      label: 'Interval',
                      value: `${selectedNode?.data.from?.value ?? ''}-${
                          selectedNode?.data.to?.value ?? ''
                      }`,
                  },
              ]
            : []),
        ...(selectedNode?.data[SAMPLE_TYPE_COL_ID]?.value?.id !== SAMPLE_CONTROL_TYPE_ID &&
        tableView.intervalType === DrillHoleReferencesIntervalType.DEPTH
            ? [
                  {
                      key: 'depth',
                      label: 'Depth',
                      value: `${selectedNode?.data.depth?.value ?? ''}`,
                  },
              ]
            : []),
        {
            key: 'column',
            label: 'Column',
            value: column?.getColDef().headerName ?? '',
        },
    ];

    Object.entries(sampleResultsProperties).forEach(([key, label]) => {
        if (key in sampleResultValues) {
            values.push({
                key,
                label,
                value: sampleResultValues[key as keyof SampleResultValues] ?? '',
            });
        }
    });

    return {
        isRanked,
        values,
        candidateEntries: isRanked ? createTableCandidateEntries(event, selectedNode) : [],
    };
};

export const createDeleteRequestPayload = (
    selectedNodes: IRowNode[],
    drillHoleId: string,
    tableViewId: string,
    rowReferences: Record<string, RowReference>,
    tableViews: Record<string, TableView>,
    gridApi: GridApi,
) => {
    // Remove temporary rows
    const updatedSelectedNodes = selectedNodes.filter((node) => !node.id?.includes('-'));

    const rowIds = updatedSelectedNodes.map((node) => node.id) as string[];
    const tables = new Set();
    let allSamples: Record<string, any> = {};
    for (const row of updatedSelectedNodes.map((node) => node.data)) {
        const rowReference = rowReferences[row.id];
        if (rowReference.children) {
            for (const key in rowReference.children) {
                const tableViewIdentifier = rowReference.children[key].tableView;
                const selectedTableView = tableViews[tableViewIdentifier];
                tables.add(selectedTableView.name);
            }
        }

        if (row.sample) {
            allSamples = findAllSampleChildren(gridApi, row, allSamples);
        }
    }
    const affectedTablesArray = Array.from(tables);
    const sampleIds = Object.keys(allSamples).filter((key) => !rowIds.includes(key)) || [];
    const requestPayload = {
        _ids: rowIds.concat(sampleIds),
        collar: drillHoleId,
        tableView: tableViewId,
    };
    return {
        affectedTablesArray,
        requestPayload,
        affectedSamplesArray:
            sampleIds.map((x) => allSamples[x]).sort((a, b) => a.localeCompare(b)) || [],
    };
};

export function getDateFormatString(locale: any, date: Date) {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(date);
    let format = '';
    let separator = '';
    for (const f of formatObj) {
        if (f.type === 'day') {
            format += 'dd';
        } else if (f.type === 'month') {
            format += 'MM';
        } else if (f.type === 'year') {
            format += 'yyyy';
        } else {
            format += f.value;
            separator = f.value;
        }
    }
    return { format, separator };
}

export function getLocaleDateFormatString() {
    const userLocale = navigator.languages?.[0] ?? navigator.language;
    return getDateFormatString(userLocale, new Date());
}

export const determineDataRecordValue = (column: ColDef, value: any) => {
    let retval;

    const type = column.type ? column.type[0] : '';

    switch (type) {
        case String(DrillHoleReferencesColumnType.PRIMARY_LIST_COLUMN):
            retval = value?.text ?? '';
            break;
        case String(DrillHoleReferencesColumnType.LIST):
        case String(DrillHoleReferencesColumnType.ASSOCIATED_COLUMN):
            retval = value?.id;
            break;
        case String(DrillHoleReferencesColumnType.NUMERIC):
            if (isValidNumber(value)) {
                retval = Number(value);
            } else {
                retval = null;
            }
            break;
        default:
            retval = value;
            break;
    }

    return retval;
};
export const updateRequired = (oldValue: any, newValue: any, newRow = false) => {
    let required = false;

    if (!oldValue && newValue?.colour === 'Clear Selection') return false;

    if (newValue !== null && newValue !== undefined) {
        if (isObject(newValue)) {
            if (newRow || !isEqual(oldValue || {}, newValue)) {
                required = true;
            }
        } else if (newRow || oldValue !== newValue) {
            required = true;
        }
    }

    return required;
};

const constructDefaultOverrides = (
    tempRowData: any,
    column: any,
    listChildren: Record<string, ListInfo>,
    listDefaultOverrides: any,
    gridApi: GridApi | null | undefined,
) => {
    for (const child of Object.values(listChildren)) {
        if (tempRowData[child.column] && !tempRowData?.[child.column]?.value?.id) {
            const parentValue = tempRowData[column].value.id;
            const cellEditorParams =
                gridApi?.getColumn(child.column)?.getColDef().cellEditorParams ?? null;
            const linkValues = cellEditorParams?.linkValues;

            if (linkValues?.[parentValue]?.defaultRow) {
                const defaultRow = linkValues[parentValue].defaultRow;

                listDefaultOverrides = {
                    ...listDefaultOverrides,
                    [child.table]: {
                        ...listDefaultOverrides[child.table],
                        [child.column]: defaultRow,
                    },
                };

                tempRowData = {
                    ...tempRowData,
                    [child.column]: { errors: [], value: { id: defaultRow } },
                };

                if (cellEditorParams.linkedChildren && !isEmpty(cellEditorParams.linkedChildren)) {
                    listDefaultOverrides = constructDefaultOverrides(
                        tempRowData,
                        child.column,
                        cellEditorParams.linkedChildren,
                        listDefaultOverrides,
                        gridApi,
                    );
                }
            }
        }
    }

    return listDefaultOverrides;
};

const createTableEntries = (
    rowReference: RowReference,
    tableId: string,
    columnValues: Record<string, any>,
    props: TableViewDrillHoleIds,
) => {
    if (!rowReference.dataRecords[tableId]) {
        rowReference.dataRecords[tableId] = {
            errors: { values: [] },
            tableView: props.tableView,
            values: {},
            verrors: { values: [] },
            rowReference: rowReference.id ?? '',
            table: tableId,
            collar: props.drillholeId,
        };
    }

    Object.entries(columnValues).forEach(([key, value]) => {
        const validValue = !!(value || value === 0);
        if (key in rowReference.dataRecords[tableId].values && !validValue) {
            delete rowReference.dataRecords[tableId].values[key];
        } else if (validValue) {
            rowReference.dataRecords[tableId].values[key] = value;
        }
    });
};

const checkForListDefaults = (
    data: any,
    column: ColDef,
    rowReference: RowReference,
    props: any,
    gridApi: GridApi | null | undefined,
) => {
    const value = data[column.colId ?? '']?.value;

    if (
        Array.isArray(column.type) &&
        (column.type[0] === DrillHoleReferencesColumnType.LIST ||
            column.type[0] === DrillHoleReferencesColumnType.ASSOCIATED_COLUMN) &&
        !isEmpty(column.cellEditorParams.linkedChildren) &&
        value?.id &&
        !data.lastRow
    ) {
        let listDefaultOverrides: Record<string, Record<string, string>> = {};
        const colId = column.colId;
        if (colId) {
            const tempRowData = {
                ...data,
                [colId]: { errors: [], value },
            };
            listDefaultOverrides = constructDefaultOverrides(
                tempRowData,
                colId,
                column.cellEditorParams.linkedChildren,
                listDefaultOverrides,
                gridApi,
            );
        }

        if (!isEmpty(listDefaultOverrides)) {
            for (const [tableId, columnValues] of Object.entries(listDefaultOverrides)) {
                createTableEntries(rowReference, tableId, columnValues, props);
            }
        }
    }
};

const constructSkeletonRowReference = (
    data: any,
    column: ColDef,
    rowReference: RowReference,
    props: any,
    tableId = '',
) => {
    const columnId = column.colId;
    const table = tableId || props.tableView;
    if (columnId && table) {
        const value = determineDataRecordValue(column, data[columnId]?.value ?? '') ?? null;

        if (specialFields.includes(columnId)) {
            rowReference.values = {
                ...rowReference.values,
                [columnId]: value,
            };
        } else {
            if (
                columnId === SAMPLE_TYPE_COL_ID &&
                data[SAMPLE_TYPE_COL_ID].value.id !== SAMPLE_CONTROL_TYPE_ID
            ) {
                createTableEntries(
                    rowReference,
                    table,
                    { [SAMPLE_CONTROL_TYPE_COL_ID]: null },
                    props,
                );
            }

            createTableEntries(rowReference, table, { [columnId]: value }, props);
        }
    }
};

export const cleanUpPayload = (
    tableViews: Record<string, TableView>,
    rowReference: RowReference,
    tableView: string,
) => {
    const tableViewObject = tableViews[tableView];

    Object.keys(rowReference.dataRecords).forEach((x: string) => {
        const validTableIds = tableViewObject.singleTable
            ? [tableViewObject.id]
            : Object.keys(tableViewObject.tables);

        if (!validTableIds.includes(x)) {
            delete rowReference.dataRecords[x];
        }
    });

    const customRowReferenceKeys = ['nextItem', 'prevItem', 'isAddedToIntervalTree'];

    customRowReferenceKeys.forEach((x: string) => {
        if (x in rowReference) {
            delete rowReference[x as keyof RowReference];
        }
    });
};

export const buildSaveRowReferencePayload = (
    event: {
        data?: any;
        column: Column;
        node: any;
        multiColumns?: Column[];
    },
    rows: Record<string, RowReference>,
    props: any,
    gridApi: GridApi | null | undefined,
    tableViews: Record<string, TableView>,
    newRow: boolean,
) => {
    const row = rows[event.node.data.id] ?? {};
    const nextItem = row.nextItem;
    const prevItem = row.prevItem;
    delete row.nextItem;
    delete row.prevItem;
    const rowReference: RowReference = newRow
        ? ({
              collar: props.drillholeId,
              tableView: props.tableView,
              activity: props.activity,
              project: props.project,
              values: {},
              dataRecords: {},
          } as RowReference)
        : cloneDeep(row);
    row.nextItem = nextItem;
    row.prevItem = prevItem;

    const columns = event.multiColumns ? event.multiColumns : [event.column];
    const columnIds = columns.map((x: Column) => x.getColId());

    rowReference.values = {
        ...rowReference.values,
        ...(newRow &&
            tableViews[props.tableView]?.intervalType ===
                DrillHoleReferencesIntervalType.INTERVAL &&
            !columnIds.includes('from') && {
                from: event?.data?.from?.value ?? event?.node?.data?.from?.value ?? null,
            }),
        ...(newRow &&
            tableViews[props.tableView]?.intervalType === DrillHoleReferencesIntervalType.DEPTH &&
            !columnIds.includes('depth') && {
                depth: event?.node?.data?.depth?.value ?? null,
            }),
    };

    for (const column of columns) {
        const groupId = column.getParent()?.getGroupId() ?? '';
        const colDef = column.getColDef();
        constructSkeletonRowReference(event.node.data, colDef, rowReference, props, groupId);
        checkForListDefaults(event.node.data, colDef, rowReference, props, gridApi);
    }

    if (
        columnIds.includes(SAMPLE_TYPE_COL_ID) &&
        event.node.data[SAMPLE_TYPE_COL_ID].value.id === SAMPLE_CONTROL_TYPE_ID
    ) {
        const tableView = tableViews[props.tableView];

        rowReference.values = {
            ...(tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL && {
                from: null,
                to: null,
            }),
            ...(tableView.intervalType === DrillHoleReferencesIntervalType.DEPTH && {
                depth: null,
            }),
        };
    }

    cleanUpPayload(tableViews, rowReference, props.tableView);

    return convertToSnake(rowReference);
};

export const processCellForClipboard = (params: ProcessCellForExportParams) => {
    const { value, column } = params;
    const colType = column.getColDef().type;
    if (Array.isArray(colType) && typeof value === 'object') {
        let tempValue = null;
        switch (colType[0]) {
            case DrillHoleReferencesColumnType.PRIMARY_LIST_COLUMN:
                tempValue = `${value?.colour ?? ''} ${value?.text ?? ''}`.trim();
                break;
            case DrillHoleReferencesColumnType.LIST:
            case DrillHoleReferencesColumnType.ASSOCIATED_COLUMN:
                tempValue = value?.primary;
                break;
            case SAMPLE_RESULTS:
            case RANKED_RESULTS:
                tempValue = value?.value?.value;
                break;
            case DrillHoleReferencesColumnType.DATE:
                if (value?.date) {
                    const convertedDate = new Date(value.date).toISOString().split('T')[0];
                    tempValue = parse(convertedDate, 'yyyy-MM-dd', new Date()).toLocaleDateString();
                }
                break;
            default:
                break;
        }

        return tempValue;
    }
    return [
        DrillHoleReferencesColumnType.CHECKBOX.toString(),
        DrillHoleReferencesColumnType.IN_USE.toString(),
    ].includes(colType?.[0] ?? '') && !value
        ? false
        : value;
};

export const evaluatePastedValue = (
    mxLists: Record<string, List>,
    colDef: ColDef,
    value: any,
    existingOldValue: boolean,
    uniqueColumnDictionary: Record<string, string[]>,
) => {
    let invalidPaste = false;
    let newValue: any = '';
    let required = false;
    let unique = false;

    const isSpecialValidationList = [
        UNIT_LIST_ID,
        ANALYTE_LIST_ID,
        SAMPLE_DECOMPOSITION_LIST_ID,
        ANALYTICAL_METHODS_LIST_ID,
    ].includes(colDef.cellEditorParams.resourceId);

    if (value) {
        const list = mxLists[colDef.cellEditorParams.listId];
        const limit =
            colDef.cellEditorParams.length ??
            (colDef.type?.[0] === DrillHoleReferencesColumnType.TEXT
                ? TEXT_FIELD_MAX_LENGTH
                : undefined);
        const dateVal = new Date(value);

        let colour = '';
        let validColour = false;
        let decodedValue = '';
        let finalColour = '';

        const validValues = ['true', 'false'];
        let modifiedValue = '';

        let rowsCollection: any = {};
        let row: any = {};

        let convertedDate = '';

        switch (colDef.type?.[0]) {
            case DrillHoleReferencesColumnType.PRIMARY_LIST_COLUMN:
                colour = value.substring(0, 8);
                validColour = /^#[0-9A-F]{6}$/i.test(colour.trim()) && colour[7] === ' ';
                decodedValue = validColour ? value.substring(8) : value;
                finalColour = validColour ? colour.trim() : '';

                if (!decodedValue) {
                    invalidPaste = true;
                    required = true;
                } else if (
                    uniqueColumnDictionary[colDef.colId ?? '']
                        ?.map((x) => (isSpecialValidationList ? x : x.toLowerCase()))
                        .includes(
                            isSpecialValidationList ? decodedValue : decodedValue.toLowerCase(),
                        )
                ) {
                    unique = true;
                    invalidPaste = true;
                } else {
                    uniqueColumnDictionary[colDef.colId ?? '']?.push(decodedValue);
                    newValue = { text: decodedValue, colour: finalColour };
                }
                break;
            case DrillHoleReferencesColumnType.CHECKBOX:
            case DrillHoleReferencesColumnType.IN_USE:
                modifiedValue = value.trim().toLowerCase();
                if (validValues.includes(modifiedValue)) {
                    newValue = modifiedValue === 'true';
                } else {
                    invalidPaste = true;
                }
                break;
            case DrillHoleReferencesColumnType.LIST:
                if (list) {
                    rowsCollection = Object.values(list.rows).reduce(
                        (accum: Record<string, string>, value: Row) => {
                            if (value.values[list.inuseColumn]) {
                                return {
                                    ...accum,
                                    [String(value.values[list.primaryColumn] ?? '')]: value.id,
                                };
                            }

                            return accum;
                        },
                        {},
                    );

                    row = rowsCollection[value];

                    if (row) {
                        newValue = createListRow(list.rows[row], list.columns, list.primaryColumn);
                    } else invalidPaste = true;
                } else invalidPaste = true;
                break;
            case DrillHoleReferencesColumnType.NUMERIC:
                if (!isNaN(Number(value))) {
                    newValue = Number(value);
                } else invalidPaste = true;
                break;
            case DrillHoleReferencesColumnType.TEXT:
            case DrillHoleReferencesColumnType.TEXTMULTI:
                newValue = String(value ?? '').substring(0, limit);
                break;
            case DrillHoleReferencesColumnType.DATE:
                if (dateVal.toString() !== 'Invalid Date' && isNaN(value)) {
                    // Need to strip timezone if one provided.
                    convertedDate = dateVal.toISOString().split('T')[0];
                    newValue = { date: parse(convertedDate, 'yyyy-MM-dd', new Date()).getTime() };
                } else invalidPaste = true;
                break;
            default:
                break;
        }
    } else if (colDef?.cellEditorParams?.requiredColumn && existingOldValue) {
        invalidPaste = true;
        required = true;
    }

    return { invalidPaste, newValue, required, unique };
};

export const extractColumnsFromGroup = (columns: any[]) => {
    if (columns.length > 0 && columns[0].groupId) {
        return columns.reduce(
            (prevValue: string[], currentValue) => prevValue.concat(currentValue.children),
            [],
        );
    }

    return columns;
};

export const getDefaultValues = (
    columns: any[],
    mxLists: Record<string, any>,
    emptyObject?: object,
) => {
    const values: Record<string, { value: any; errors: string[] }> = {};
    for (const col of columns) {
        if (!col) {
            continue;
        }
        const traversedColumns: string[] = [];
        const type = col.type ? col.type[0] : '';
        if (
            (type === DrillHoleReferencesColumnType.LIST ||
                type === DrillHoleReferencesColumnType.ASSOCIATED_COLUMN) &&
            col.colId !== SAMPLE_CONTROL_TYPE_COL_ID
        ) {
            const lst = mxLists[col.cellEditorParams?.listId ?? ''];
            if (!lst) {
                continue;
            }
            const row = lst.rows[getDefaultValueId(col, columns, traversedColumns)] ?? {};
            const item = createListRow(row, lst.columns, lst.primaryColumn, emptyObject);
            values[col.colId] = { value: item, errors: [] };
        }
    }
    return values;
};

export const getDefaultValueId = (
    column: any,
    columns: any[],
    traversedColumns: string[],
): string => {
    if (column.cellEditorParams?.defaultRow) return column.cellEditorParams?.defaultRow;

    if (column.cellEditorParams.linkedColumn) {
        const parentCol = columns.find((x) => x.colId === column.cellEditorParams.linkedColumn);

        if (parentCol && !traversedColumns.includes(parentCol.colId)) {
            // Need to keep track of traversed columns, can link columns into a loop.
            traversedColumns.push(parentCol.colId);
            const parentDefault = getDefaultValueId(parentCol, columns, traversedColumns);
            if (parentDefault) {
                return column.cellEditorParams.linkValues[parentDefault]?.defaultRow ?? '';
            }
        }
    }
    return '';
};

export const createInsertPayload = (
    tableView: TableView,
    rowReference: RowReference,
    props: TableViewDrillHoleIds,
    insertBelow: boolean,
) => {
    const currentIndex = rowReference.index;
    const emptyRowReference = {
        tableView: props.tableView,
        collar: props.drillholeId,
    } as RowReference;
    let neighbour;
    if (!insertBelow) {
        // Insert Above
        neighbour = rowReference.prevItem;
        emptyRowReference.index = neighbour
            ? (currentIndex + neighbour.index) / 2
            : currentIndex - 10;
    } else {
        // Insert Below
        neighbour = rowReference.nextItem;
        emptyRowReference.index = neighbour
            ? (currentIndex + neighbour.index) / 2
            : currentIndex + 10;
    }
    let values = {};
    if (tableView.intervalType === DrillHoleReferencesIntervalType.INTERVAL) {
        values = { from: null, to: null };
    } else if (tableView.intervalType === DrillHoleReferencesIntervalType.DEPTH) {
        values = { depth: null };
    }
    emptyRowReference.values = values;
    const reIndex =
        neighbour &&
        (Math.abs(emptyRowReference.index - rowReference.index) < 10 ||
            Math.abs(emptyRowReference.index - neighbour.index) < 10);
    return { emptyRowReference, reIndex };
};

export const copyRowReference = (
    rowReference: RowReference,
    props: TableViewDrillHoleIds,
    gridApi: GridApi | null | undefined,
    rowOverride: any,
) => {
    if (!gridApi) return null;

    rowReference.dataRecords = {};

    rowReference.values = {};

    gridApi.getColumns()?.forEach((column) => {
        const tableId = column.getParent()?.getGroupId() ?? props.tableView;
        const colId = column.getColId();
        const colDef = column.getColDef();

        if (
            !colDef.cellEditorParams.linkedToLitho &&
            ![SAMPLE_NUMBER_COL_ID, SAMPLE_CONTROL_TYPE_COL_ID, PARENT_SAMPLE].includes(colId) &&
            !colDef.type?.includes(SAMPLE_RESULTS) &&
            !colDef.type?.includes(RANKED_RESULTS) &&
            !colDef.type?.includes(SAMPLE_INFO_COLUMNS) &&
            !colDef.type?.includes(INFO_COLUMN)
        ) {
            let value;

            if (specialFields.includes(colId)) {
                rowReference.values = {
                    ...rowReference.values,
                    [colId]: rowOverride.row[colId].value,
                };
            } else {
                if (colId in rowOverride.valueOverrides) {
                    value = rowOverride.valueOverrides[colId];
                } else {
                    value = determineDataRecordValue(colDef, rowOverride.row[colId].value);
                }

                createTableEntries(rowReference, tableId, { [colId]: value }, props);
            }
        }
    });

    return rowReference;
};
type FocusedCellType = CellPosition | null | undefined;
type FocusedRowType = IRowNode | null | false;
export const disableDeleteMenuOption = (
    params: GetContextMenuItemsParams,
    focusedCell: FocusedCellType,
    focusedRow: FocusedRowType,
    selectedNodes: IRowNode[] | undefined,
    rowReferences: Record<string, RowReference>,
    tableView: string,
) =>
    // Right click for Delete Selected rows doesn't happen within the grid, or it is not on
    // one of the selected row, or temporary is the only selected row disable delete menu
    params.column?.getColDef().cellEditorParams.linkedToLitho ||
    (!params.node?.data.lastRow && params.node?.id?.includes('-')) ||
    !focusedCell ||
    !selectedNodes?.find((node: IRowNode) => node.rowIndex === focusedCell?.rowIndex) ||
    (!(focusedRow && (focusedRow.id as string) in rowReferences) && selectedNodes?.length === 1) ||
    (tableView.includes(COORDINATES_ID) && selectedNodes.length !== 1);

export const disableInsertMenuOption = (
    params: GetContextMenuItemsParams,
    focusedCell: CellPosition | null | undefined,
    focusedRow: IRowNode | null | false,
    selectedNodes: IRowNode[] | undefined,
    rowReferences: Record<string, RowReference>,
    isSingleRowTable: boolean,
) =>
    // Right click for Insert row doesn't happen within the grid, or it is on the temporary
    // row or more than one rows are selected. Insert option will be disabled.
    params.column?.getColDef().cellEditorParams.linkedToLitho ||
    (!params.node?.data.lastRow && params.node?.id?.includes('-')) ||
    isSingleRowTable ||
    !focusedCell ||
    !(focusedRow && (focusedRow.id as string) in rowReferences) ||
    selectedNodes?.length !== 1 ||
    !selectedNodes?.find((node: IRowNode) => node.rowIndex === focusedCell?.rowIndex);

export const disableMergeMenuOption = (
    params: GetContextMenuItemsParams,
    focusedCell: CellPosition | null | undefined,
    selectedNodes: IRowNode[] | undefined,
    rowIndexes: (number | null)[],
    isSample: boolean,
    tableView: TableView,
    isSingleRowTable: boolean,
) =>
    params.column?.getColDef().cellEditorParams.linkedToLitho ||
    (!params.node?.data.lastRow && params.node?.id?.includes('-')) ||
    isSingleRowTable ||
    !focusedCell ||
    !selectedNodes?.find((node: IRowNode) => node.rowIndex === focusedCell?.rowIndex) ||
    isSample ||
    tableView.intervalType !== DrillHoleReferencesIntervalType.INTERVAL ||
    (!!selectedNodes &&
        (selectedNodes.length < 2 ||
            selectedNodes[selectedNodes?.length - 1].data.lastRow ||
            (!selectedNodes[0].data.from.value && selectedNodes[0].data.from.value !== 0) ||
            (!selectedNodes[selectedNodes.length - 1].data.to.value &&
                selectedNodes[selectedNodes.length - 1].data.to.value !== 0))) ||
    rowIndexes.some((val, idx) => {
        if (val !== null && idx + 1 < rowIndexes.length) {
            return idx !== rowIndexes.length - 1 && val + 1 !== rowIndexes[idx + 1];
        } else {
            return false;
        }
    });

export const disableSplitMenuOption = (
    params: GetContextMenuItemsParams,
    focusedCell: FocusedCellType,
    focusedRow: FocusedRowType,
    rowReferences: Record<string, RowReference>,
    selectedNodes: IRowNode[] | undefined,
    isSample: boolean,
    tableView: TableView,
    isSingleRowTable: boolean,
) =>
    params.column?.getColDef().cellEditorParams.linkedToLitho ||
    (!params.node?.data.lastRow && params.node?.id?.includes('-')) ||
    isSingleRowTable ||
    !focusedCell ||
    !(focusedRow && (focusedRow.id as string) in rowReferences) ||
    isSample ||
    selectedNodes?.length !== 1 ||
    !selectedNodes?.find((node: IRowNode) => node.rowIndex === focusedCell?.rowIndex) ||
    tableView.intervalType !== DrillHoleReferencesIntervalType.INTERVAL ||
    !(
        (focusedRow.data.from.value || focusedRow.data.from.value === 0) &&
        (focusedRow.data.to.value || focusedRow.data.to.value === 0) &&
        focusedRow.data.from.value < focusedRow.data.to.value
    );

export const getColumnNameWithoutAsterisk = (name: string) => {
    let result = '';
    if (name) {
        result = name.replace(' *', '');
    }
    return result;
};

export const findRowReferenceToInsert = (
    row: any,
    currentRowReference: RowReference,
    rowReferences: Record<string, RowReference>,
    gridApi: GridApi | null | undefined,
) => {
    const rowReference = rowReferences[row.id];

    if (rowReference && rowReference.index > currentRowReference.index) {
        currentRowReference = rowReference;
    }

    if (row.childSamples) {
        Object.keys(row.childSamples).forEach((x) => {
            const newRow = gridApi?.getRowNode(x);

            if (newRow) {
                currentRowReference = findRowReferenceToInsert(
                    newRow.data,
                    currentRowReference,
                    rowReferences,
                    gridApi,
                );
            }
        });
    }

    return currentRowReference;
};

export const adjustSpansAfterFilter = (gridApi: GridApi | undefined | null, rows: any) => {
    const newRows: any = Object.values(rows).reduce((accum: any, x: any) => {
        if (!x.id.includes('-')) {
            accum[x.id] = x;
        }
        return accum;
    }, {} as any);
    let currentId = '';
    let currentParentId = '';
    let currentSpanCount = 1;
    gridApi?.forEachNodeAfterFilter((x: IRowNode) => {
        const newRow = (x.id ?? '') in rows ? { ...rows[x.id ?? ''] } : { ...x };
        const validRow = !newRow.id?.includes('-') || newRow.lastRow;
        const validSpanRow = !!(
            validRow &&
            (newRow[ROW_SPAN]?.value || newRow[PARENT_ROW_SPAN]?.value)
        );

        if (validSpanRow && !currentId) {
            currentId = newRow.id ?? '';
            currentParentId = newRow[PARENT_ROW_SPAN]?.value
                ? newRow[PARENT_ROW_SPAN]?.value
                : newRow.id;
            newRow[PARENT_ROW_SPAN] = new CellData('');
            currentSpanCount = 1;
        } else if (currentParentId) {
            if (newRow[PARENT_ROW_SPAN]?.value !== currentParentId) {
                newRows[currentId] = {
                    ...newRows[currentId],
                    [ROW_SPAN]: new CellData(currentSpanCount),
                };

                if (validSpanRow) {
                    currentId = newRow.id ?? '';
                    currentParentId = newRow[PARENT_ROW_SPAN]?.value
                        ? newRow[PARENT_ROW_SPAN]?.value
                        : newRow.id;
                } else {
                    currentId = '';
                    currentParentId = '';
                }

                currentSpanCount = 1;
            } else {
                newRow[PARENT_ROW_SPAN] = new CellData(currentId);
                currentSpanCount += 1;
            }
        }

        if (validRow) {
            newRows[newRow.id ?? ''] = newRow;
        }
    });

    if (currentParentId) {
        newRows[currentId] = { ...newRows[currentId], [ROW_SPAN]: new CellData(currentSpanCount) };
    }

    return Object.values(newRows);
};

export const getAnchorTopLeft = (
    activeElement: Element | null,
    height: number,
    width: number,
    elementTopOffset: number,
    elementLeftOffset: number,
    elementBottomOffset: number,
    elementRightOffset: number,
) => {
    let top = 0;
    let left = 0;
    if (!activeElement) {
        return { top, left };
    }
    const rect = activeElement.getBoundingClientRect();
    const screenHeight = window.innerHeight + 200;
    const screenWidth = window.innerWidth;
    const activeElementTop = rect.top + elementTopOffset;
    const activeElementLeft = rect.left + elementLeftOffset;
    const activeElementBottom = rect.bottom + elementBottomOffset;
    const activeElementRight = rect.right + elementRightOffset;
    if (activeElementBottom > screenHeight / 2 && activeElementRight > screenWidth / 2) {
        // Show popover above the element anchored to top right
        top = activeElementTop - height;
        left = activeElementRight - width;
    } else if (activeElementBottom > screenHeight / 2 && activeElementRight <= screenWidth / 2) {
        // Show popover above the element anchored to top left
        top = activeElementTop - height;
        left = activeElementLeft;
    } else if (activeElementBottom <= screenHeight / 2 && activeElementRight > screenWidth / 2) {
        // Show popover below the element anchored to bottom right
        top = activeElementBottom;
        left = activeElementRight - width;
    } else if (activeElementBottom <= screenHeight / 2 && activeElementRight <= screenWidth / 2) {
        // Show popover below the element anchored to bottom left
        top = activeElementBottom;
        left = activeElementLeft;
    }
    return { top, left };
};

const flattenColumns = (colData: any[]) => {
    if (colData.length === 0) return [];
    const childrenKey = 'children';
    const isGroupedColumns = childrenKey in colData[0];
    if (isGroupedColumns) {
        const result = [];
        for (const group of colData) {
            for (const child of group[childrenKey]) {
                result.push(child);
            }
        }
        return result;
    }
    return colData;
};

const getFilteredRowNodes = (
    selectedRowId: string | undefined,
    gridApi: GridApi | undefined | null,
) => {
    const rowData: IRowNode[] = [];
    let isRowPresent = false;
    let rowIndex;
    gridApi?.forEachNodeAfterFilter((rowNode, index) => {
        if (rowNode.id === selectedRowId) {
            isRowPresent = true;
            rowIndex = index;
        }
        rowData.push(rowNode);
    });
    return { rowData, isRowPresent, rowIndex };
};

export const goToErrorForDirection = (
    selectedRow: {
        id: string | undefined;
        toValue: number | undefined;
        index: number | undefined;
    } | null,
    selectedCol: string | undefined | null,
    direction: string,
    gridApi: GridApi | null | undefined,
    automaticallyReFocusCell: boolean,
    openedTooltipComponentRef: any,
    updateFn: Function = () => {},
) => {
    if (!selectedRow || !selectedCol) {
        return;
    }
    const { rowData, isRowPresent } = getFilteredRowNodes(selectedRow.id, gridApi);
    if (rowData.length === 0 || !isRowPresent) {
        return;
    }
    const loopEndCondition = (direction: string, index: number, end: number) =>
        direction === ERROR_DIRECTION.NEXT ? index < end : index > end;
    const loopStep = (direction: string, index: number) =>
        direction === ERROR_DIRECTION.NEXT ? index + 1 : index - 1;
    const currentRowIndex = selectedRow.index ?? 0;
    const currentCol = selectedCol;
    const colData = gridApi?.getAllDisplayedColumnGroups() ?? [];
    const currentColOrder = flattenColumns(colData);
    let isCurrentColTraversed = false;
    let errorCol = '';
    let errorRow = currentRowIndex;
    const rowStart = currentRowIndex;
    let rowEnd;
    let colStart;
    let colEnd;
    if (direction === ERROR_DIRECTION.NEXT) {
        rowEnd = rowData.length;
        colStart = 0;
        colEnd = currentColOrder.length;
    } else {
        rowEnd = -1;
        colStart = currentColOrder.length - 1;
        colEnd = -1;
    }
    for (let i = rowStart; loopEndCondition(direction, i, rowEnd); i = loopStep(direction, i)) {
        const row = rowData[i]?.data;

        if (!row) {
            // eslint-disable-next-line no-console
            console.log(
                `index: ${i}, 
                length: ${rowData.length}, 
                loopEndCondition: ${loopEndCondition(direction, i, rowEnd)}, 
                loopStep: ${loopStep(direction, i)}, 
                direction: ${direction},
                rowStart: ${rowStart},
                rowEnd: ${rowEnd}`,
            );
            continue;
        }

        for (let j = colStart; loopEndCondition(direction, j, colEnd); j = loopStep(direction, j)) {
            const col = currentColOrder[j];
            if (i === currentRowIndex && !isCurrentColTraversed) {
                if (col.colId === currentCol) {
                    isCurrentColTraversed = true;
                }
            } else {
                const id = col.colId ?? '';
                const data = row[id] ?? {};
                if (data?.errors?.length) {
                    errorCol = id;
                    errorRow = i;
                    break;
                }
            }
        }
        if (errorCol) {
            break;
        }
    }

    if (errorCol && automaticallyReFocusCell) {
        goToSelectedRowColWithIndex(gridApi, openedTooltipComponentRef, errorRow, errorCol);
    }

    setTimeout(() => {
        updateFn(gridApi, openedTooltipComponentRef, errorCol ? { errorCol, errorRow } : null);
    }, 100);
};

export const goToSelectedRowColWithIndex = (
    gridApi: GridApi | null | undefined,
    openedTooltipComponentRef: any,
    rowNumber: number,
    column: string,
    updateFn: Function = () => {},
) => {
    if (column) {
        gridApi?.clearFocusedCell();
        gridApi?.clearCellSelection();
        gridApi?.ensureIndexVisible(rowNumber);
        gridApi?.ensureColumnVisible(column);
        setTimeout(() => {
            // Need a timeout for large grids to ensure the row is rendered before
            // we focus on it. Otherwise keyboard navigation doesn't work
            gridApi?.setFocusedCell(rowNumber, column);
            updateFn(gridApi, openedTooltipComponentRef, { errorCol: column, errorRow: rowNumber });
        }, 100);
    }
};

export const postErrorNavFunction = (
    gridApi: GridApi | null,
    openedTooltipComponentRef: any,
    error?: { errorCol: string; errorRow: number },
) => {
    const rowNode = gridApi?.getDisplayedRowAtIndex(error?.errorRow ?? 0);
    if (rowNode) {
        const params = { columns: [error?.errorCol ?? ''], rowNodes: [rowNode] };
        const instances = gridApi?.getCellRendererInstances(params);
        if (openedTooltipComponentRef.current) {
            openedTooltipComponentRef.current.closeTooltip();

            openedTooltipComponentRef.current = null;
        }
        if ((instances?.length ?? 0) > 0) {
            const instance = instances?.[0];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            instance.openTooltip();

            openedTooltipComponentRef.current = instance;
        }
    }
};

export const gotoSelectedRowCol = (
    selectedRow: {
        id: string | undefined;
        toValue: number | undefined;
        index: number | undefined;
    } | null,
    selectedCol: string | undefined | null,
    gridApi: GridApi | null | undefined,
) => {
    if (!selectedRow || !selectedCol) {
        return;
    }
    const selCol = selectedCol;
    const { rowData, isRowPresent, rowIndex } = getFilteredRowNodes(selectedRow.id, gridApi);
    if (rowData.length === 0) {
        return;
    }
    if (!isRowPresent || rowIndex === undefined) {
        let newIndex: number | undefined;
        if (selectedRow?.index !== undefined) {
            if (rowData.length >= selectedRow.index + 1) {
                newIndex = selectedRow.index + 1;
            } else if (rowData.length >= selectedRow.index - 1 && selectedRow.index - 1 >= 0) {
                newIndex = selectedRow.index - 1;
            }
        }
        newIndex = newIndex ?? 0;
        // If the row is gone focus on the closest row
        gridApi?.ensureIndexVisible(newIndex, 'top');
        gridApi?.setFocusedCell(newIndex, selCol);
        setTimeout(() => {
            // Need a timeout for large grids to ensure the row is rendered before
            // we focus on it. Otherwise keyboard navigation doesn't work
            gridApi?.setFocusedCell(newIndex ?? 0, selCol);
        }, TIMEOUT);
        return;
    }
    gridApi?.clearFocusedCell();
    gridApi?.clearCellSelection();
    gridApi?.ensureIndexVisible(rowIndex);
    gridApi?.ensureColumnVisible(selCol);
    setTimeout(() => {
        // Need a timeout for large grids to ensure the row is rendered before
        // we focus on it. Otherwise keyboard navigation doesn't work
        gridApi?.setFocusedCell(rowIndex, selCol);
    }, TIMEOUT);
};

const customBlankFilters = [
    {
        displayKey: 'customBlank',
        displayName: 'Blank',
        predicate: (_filterValues: any[], cellValue: any) => {
            const newValue = typeof cellValue === 'string' ? JSON.parse(cellValue) : cellValue;
            return !newValue?.value;
        },
        numberOfInputs: 0,
    },
    {
        displayKey: 'customNotBlank',
        displayName: 'Not blank',
        predicate: (_filterValues: any[], cellValue: any) => {
            const newValue = typeof cellValue === 'string' ? JSON.parse(cellValue) : cellValue;
            return !!newValue?.value;
        },
        numberOfInputs: 0,
    },
];

const customGenericFilters = [
    {
        displayKey: 'errorsInColumn',
        displayName: 'Errors in column',
        predicate: (_filterValues: any[], cellValue: any) => {
            const newValue = typeof cellValue === 'string' ? JSON.parse(cellValue) : cellValue;
            return newValue?.error;
        },
        numberOfInputs: 0,
    },
];

export const numericFilters = [
    {
        displayKey: 'customEquals',
        displayName: 'Equals',
        predicate: ([fv1]: number[], cellValue: any) =>
            isValidNumber(cellValue?.value) && cellValue.value === fv1,
        numberOfInputs: 1,
    },
    {
        displayKey: 'customNotEqual',
        displayName: 'Not equal',
        predicate: ([fv1]: number[], cellValue: any) =>
            isValidNumber(cellValue?.value) && cellValue.value !== fv1,
        numberOfInputs: 1,
    },
    {
        displayKey: 'customLessThan',
        displayName: 'Less than',
        predicate: ([fv1]: number[], cellValue: any) =>
            isValidNumber(cellValue?.value) && cellValue.value < fv1,
        numberOfInputs: 1,
    },
    {
        displayKey: 'customLessThanOrEquals',
        displayName: 'Less than or equals',
        predicate: ([fv1]: number[], cellValue: any) =>
            isValidNumber(cellValue?.value) && cellValue.value <= fv1,
        numberOfInputs: 1,
    },
    {
        displayKey: 'customGreaterThan',
        displayName: 'Greater than',
        predicate: ([fv1]: number[], cellValue: any) =>
            isValidNumber(cellValue?.value) && cellValue.value > fv1,
        numberOfInputs: 1,
    },
    {
        displayKey: 'customGreaterThanOrEquals',
        displayName: 'Greater than or equals',
        predicate: ([fv1]: number[], cellValue: any) =>
            isValidNumber(cellValue?.value) && cellValue.value >= fv1,
        numberOfInputs: 1,
    },
    {
        displayKey: 'customInRange',
        displayName: 'In range',
        predicate: ([fv1, fv2]: number[], cellValue: any) =>
            isValidNumber(cellValue?.value) && cellValue.value >= fv1 && cellValue.value <= fv2,
        numberOfInputs: 2,
    },
    {
        displayKey: 'customBlank',
        displayName: 'Blank',
        predicate: (_filterValues: any[], cellValue: any) => !isValidNumber(cellValue?.value),
        numberOfInputs: 0,
    },
    {
        displayKey: 'customNotBlank',
        displayName: 'Not blank',
        predicate: (_filterValues: any[], cellValue: any) => isValidNumber(cellValue?.value),
        numberOfInputs: 0,
    },
    ...customGenericFilters,
];

export const customTextMatcher = (params: TextMatcherParams) => {
    const { filterOption, value, filterText } = params;
    if (filterText === null || filterText === undefined) {
        return false;
    }

    const newValue = JSON.parse(value);
    const index = newValue?.value?.lastIndexOf(filterText);
    switch (filterOption) {
        case 'contains':
            return newValue?.value?.indexOf(filterText) >= 0;
        case 'notContains':
            return newValue?.value?.indexOf(filterText) < 0;
        case 'equals':
            return newValue?.value === filterText;
        case 'notEqual':
            return newValue?.value !== filterText;
        case 'startsWith':
            return newValue?.value?.indexOf(filterText) === 0;
        case 'endsWith':
            return index >= 0 && index === newValue?.value?.length - filterText.length;
        default:
            return false;
    }
};

export const textFilters = [
    'contains',
    'notContains',
    'equals',
    'notEqual',
    'startsWith',
    'endsWith',
    ...customBlankFilters,
    ...customGenericFilters,
];

export const dateComparator = (filterLocalDateAtMidnight: Date, cellValue: any) => {
    if (!cellValue?.value) return;

    let returnVal = 0;

    if (cellValue.value < filterLocalDateAtMidnight) {
        returnVal = -1;
    } else if (cellValue.value > filterLocalDateAtMidnight) {
        returnVal = 1;
    }

    return returnVal;
};

export const dateFilters = [
    'equals',
    'greaterThan',
    'lessThan',
    'notEqual',
    'inRange',
    ...customBlankFilters,
    ...customGenericFilters,
];

export const getValidationErrorCount = (rows: Record<string, any>) => {
    const array = Object.values(rows);
    let errorCount = 0;
    let orderCount = 0;
    let rowCount = 0;

    const errorRows = [];

    for (const rr of array) {
        if (rr.id.includes('-')) continue;
        if (rr[OUT_OF_ORDER]) orderCount += 1;

        const cellData = Object.values(rr);
        let hasError = false;
        cellData.forEach((cData: unknown) => {
            const cd = cData as CellData;
            // this CellData collection contains an id:string. we add when the object contains a collection of errors
            errorCount += cd?.errors?.length ?? 0;
            if (cd?.errors?.length) {
                hasError = true;
            }
        });
        if (hasError) {
            errorRows.push(rr.id);
        }

        rowCount += 1;
    }

    return {
        errorCount,
        errorRows,
        orderCount,
        rowCount,
    };
};

export const handlePaste =
    (
        params: ProcessDataFromClipboardParams,
        copyDataInfo: React.MutableRefObject<{
            columns: Record<string, Column>;
            data: Record<string, any>;
        }>,
        alertModalInfo: React.MutableRefObject<{
            reason: AlertModalOptions;
            params: any;
        }>,
        overwrite: React.MutableRefObject<OverwriteType>,
    ) =>
    (
        mxLists: Record<string, List>,
        setAlertDialogOpen: Function,
        pasteOptions: string,
        additionalProps?: {
            readOnly?: boolean;
            isSingleRowTable?: boolean;
            isEditable?: Function;
            requireObjectId?: boolean;
        },
    ) => {
        copyDataInfo.current = { columns: {}, data: {} };

        const {
            readOnly = false,
            isSingleRowTable = false,
            isEditable = () => true,
            requireObjectId = false,
        } = additionalProps ?? {};

        const currentCell = params.api.getFocusedCell();
        let trailingNewLine = false;

        if (!currentCell) return null;

        const rowIndex = currentCell?.rowIndex;
        const uniqueColumnDictionary: Record<string, string[]> = {};
        const modifiedRows: string[] = [];
        const originalColDef = currentCell?.column?.getColDef();
        const columns = params.api.getAllDisplayedColumns();
        const columnIndex = columns.findIndex((x: Column) => x.getColId() === originalColDef.colId);
        const excludedPasteColumns = [SAMPLE_TYPE_COL_ID, SAMPLE_CONTROL_TYPE_COL_ID];
        const originalRow = params.api.getDisplayedRowAtIndex(rowIndex);

        for (let i = rowIndex; i < rowIndex + params.data.length; i += 1) {
            const data = params.data[i - rowIndex];
            const row = params.api.getDisplayedRowAtIndex(i);

            if (
                row?.id &&
                !row.data.lastRow &&
                !(
                    i === rowIndex + params.data.length - 1 &&
                    params.data.length > 1 &&
                    data.length === 1 &&
                    !data[0]
                )
            ) {
                modifiedRows.push(row?.id);
            }
        }

        if (!originalRow) return null;

        if (params.data.length > 500) {
            setAlertDialogOpen(true);

            alertModalInfo.current.reason = AlertModalOptions.ROW_LIMIT;
            return null;
        }

        if (
            isEditable({ colDef: originalColDef, data: originalRow.data }) &&
            originalColDef.type?.[0] === DrillHoleReferencesColumnType.LIST &&
            params.data.length === 1 &&
            params.data[0].length === 1
        ) {
            navigator.clipboard
                .readText()
                .then((text) => {
                    params.api?.startEditingCell({
                        rowIndex,
                        colKey: currentCell.column,
                        key: text,
                    });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('Error reading clipboard text', error);
                });
            return null;
        }

        if (readOnly || isSingleRowTable || params.api.isAnyFilterPresent()) {
            setAlertDialogOpen(true);

            if (readOnly) {
                alertModalInfo.current.reason = AlertModalOptions.READ_ONLY;
            } else {
                alertModalInfo.current.reason = isSingleRowTable
                    ? AlertModalOptions.SINGLE_ROW_TABLE
                    : AlertModalOptions.FILTER_APPLIED;
            }

            return null;
        }

        for (let i = 0; i < params.data.length; i += 1) {
            const data = params.data[i];

            if (
                i === params.data.length - 1 &&
                params.data.length > 1 &&
                data.length === 1 &&
                !data[0]
            ) {
                // Case handles where excel copies row with newline at end
                trailingNewLine = true;
                break;
            }

            const newRowIndex = rowIndex + i;
            const row = params.api.getDisplayedRowAtIndex(newRowIndex);
            const newId = requireObjectId ? generateObjectId()() : uuidv4();
            const rowId = row?.id ? row.id : newId;
            const newData = row ? { ...row.data } : { id: rowId, lastRow: true };

            if (additionalProps?.requireObjectId && newData.id.includes('-')) {
                newData.id = generateObjectId()();
            }

            for (let j = 0; j < data.length; j += 1) {
                const column = columns[columnIndex + j];
                if (!column) {
                    if (pasteOptions === PASTE_OPTIONS.FAIL_FAST) {
                        setAlertDialogOpen(true);

                        alertModalInfo.current.reason = AlertModalOptions.COLUMNS_EXCEEDED;
                        return null;
                    }
                    continue;
                }

                const colDef = column.getColDef();
                const colId = colDef.colId ?? '';
                const columnGroup = column.getParent()?.getColGroupDef()?.headerName ?? '';
                const columnName = getColumnNameWithoutAsterisk(colDef.headerName ?? '');
                const finalColumnName = columnGroup
                    ? `'${columnGroup} - ${columnName}'`
                    : `'${columnName}'`;

                if (
                    colDef.cellEditorParams.unique &&
                    colDef.cellEditorParams.resourceId &&
                    !(colId in uniqueColumnDictionary)
                ) {
                    uniqueColumnDictionary[colId] = [];
                    const list = mxLists[colDef.cellEditorParams.resourceId];
                    uniqueColumnDictionary[colId] = Object.values(list.rows)
                        .filter(
                            (x) => !!x.values[list.primaryColumn] && !modifiedRows.includes(x.id),
                        )
                        .map((x) => x.values[list.primaryColumn]?.toString() ?? '');
                }

                if (
                    colDef.cellEditorParams.length &&
                    data[j]?.length &&
                    data[j].length > colDef.cellEditorParams.length
                ) {
                    if (pasteOptions === PASTE_OPTIONS.FAIL_FAST) {
                        setAlertDialogOpen(true);

                        alertModalInfo.current = {
                            reason: AlertModalOptions.LENGTH_EXCEEDED,
                            params: {
                                column: finalColumnName,
                                length: colDef.cellEditorParams.length,
                            },
                        };
                        return null;
                    }

                    continue;
                }

                const suppressPaste: boolean =
                    typeof colDef.suppressPaste === 'boolean' ? colDef.suppressPaste : false;

                const nonEditableCell = !isEditable({
                    colDef: column.getColDef(),
                    data: newData,
                });
                const suppressedColumns = suppressPaste || excludedPasteColumns.includes(colId);

                if (suppressedColumns || nonEditableCell) {
                    if (pasteOptions === PASTE_OPTIONS.FAIL_FAST) {
                        setAlertDialogOpen(true);

                        alertModalInfo.current = suppressedColumns
                            ? {
                                  reason: columnName
                                      ? AlertModalOptions.INVALD_PASTE_COLUMN
                                      : AlertModalOptions.INVALID_PASTE_COLUMN_GENERIC,
                                  params: { column: finalColumnName },
                              }
                            : {
                                  reason:
                                      newData.id.includes('-') && !newData.lastRow
                                          ? AlertModalOptions.TEMPORARY_ROW
                                          : AlertModalOptions.INVALID_PASTE_CELL,
                                  params: {
                                      column: finalColumnName,
                                      rowNumber: newData[ROW_NUMBER]?.value,
                                  },
                              };
                        return null;
                    }
                    continue;
                }

                if (colId && !copyDataInfo.current?.columns?.[colId]) {
                    copyDataInfo.current.columns[colId] = column;
                }

                const oldValue = newData[colId]?.value;
                const existingOldValue = !!(oldValue || oldValue === 0);
                const { invalidPaste, newValue, required, unique } = evaluatePastedValue(
                    mxLists,
                    colDef,
                    data[j],
                    existingOldValue,
                    uniqueColumnDictionary,
                );

                if (invalidPaste) {
                    if (pasteOptions === PASTE_OPTIONS.FAIL_FAST || unique) {
                        let reason = AlertModalOptions.INVALID_PASTE_VALUE;

                        if (unique) {
                            reason = AlertModalOptions.UNIQUE_COLUMN;
                        } else if (required) {
                            reason = AlertModalOptions.CLEAR_REQUIRED_COLUMN;
                        }

                        setAlertDialogOpen(true);

                        alertModalInfo.current = {
                            reason,
                            params: { column: finalColumnName, value: `'${data[j]}'` },
                        };
                        return null;
                    }
                    continue;
                }

                if (
                    !newData.lastRow &&
                    overwrite.current !== OverwriteType.PASTE &&
                    existingOldValue
                ) {
                    overwrite.current = OverwriteType.PASTE;
                }

                if (updateRequired(oldValue ?? '', newValue, newData.lastRow)) {
                    if (!copyDataInfo.current.data[rowId]) {
                        copyDataInfo.current.data[rowId] = newData;
                    }

                    copyDataInfo.current.data[rowId][colId] = {
                        errors: [],
                        value: newValue,
                    };
                }
            }
        }

        const newPasteData = params.data.slice(0);

        if (trailingNewLine) newPasteData.splice(params.data.length - 1);

        return newPasteData;
    };

export const overlappedIntervals = (
    row: any,
    rows: any,
    rowReferenceIntervalTree: IntervalTree | null,
    isSample: boolean,
    overlapInterval: string,
) => {
    if (
        !isValidNumber(row?.from?.value) ||
        !isValidNumber(row?.to?.value) ||
        row?.from?.value >= row?.to?.value
    ) {
        return [];
    }
    let overlapMatches = [];
    const overlapMatchesTemp =
        rowReferenceIntervalTree?.search(
            [
                row?.from?.value ?? Number.MIN_SAFE_INTEGER,
                row?.to?.value ?? Number.MIN_SAFE_INTEGER,
            ],
            (rId, _interval) => rId,
        ) ?? [];
    if (isSample) {
        // For samples table we need to ensure that the sample type matches as well
        for (const id of overlapMatchesTemp) {
            if (
                (row[SAMPLE_TYPE_COL_ID]?.value?.primary ?? '') ===
                (rows[id ?? ''][SAMPLE_TYPE_COL_ID]?.value?.primary ?? '')
            ) {
                overlapMatches.push(id);
            }
        }
    } else {
        overlapMatches = overlapMatchesTemp;
    }

    return overlapMatches?.length === 1 && overlapMatches.includes(overlapInterval as any)
        ? []
        : overlapMatches;
};
